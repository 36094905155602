import ApiService from "@/common/api.service";

export default {
  getUserExam(userExamId) {
    return ApiService.post(`/user-exams/${userExamId}/start`);
  },
  updatePracticeAnswers(userExamId ,data){
    return ApiService.post(`/user-exams/${userExamId}/answer`, data);
  },
  submitExam(userExamId) {
    return ApiService.post(`/user-exams/${userExamId}/submit`);
  },
  getExplanation(examId, data) {
    return ApiService.query(`exams/${examId}`, data);
  },
  updateExamMetaData(userExamId, data) {
    return ApiService.put(`/user-exams/${userExamId}/metadata`, data);
  }
};
