<template>
  <div>
    <div class="page-wrapper" id="sandbox">
      <div class="sidebar-wrapper p-4" data-simplebar="init">
        <p class="articleTitle">
          {{ Directions[testType].Title }}
        </p>
        <div class="direction" v-html="Directions[testType].Direction"></div>
      </div>
      <div class="page-content-wrapper" style="height: auto !important;"></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: {
    testType: {
      type: String,
      default: "English",
      validator: value =>
        ["English", "Math", "Reading", "Science"].includes(value)
    }
  },
  data() {
    return {
      Directions: {
        English: {
          Title: "English Test Directions",
          Direction: `You will have 45 minutes to complete this section.<br />
            SECTION DIRECTIONS: The first screen in this section contains instructions about the English test. That screen is not part of the scored questions. You can return to the instructions at any time by selecting the <b>Nav</b> button at the top of the screen and then selecting <b>Instr</b>.<br />
            You may use your scratch paper on this test. You will hand that in to the room supervisor at the end of testing.<br />
            Select the <b>Next</b> button to proceed.`
        },
        Math: {
          Title: "Mathematics Test Directions",
          Direction: `You will have 60 minutes to complete this section.<br />
            SECTION DIRECTIONS: The first screen in this section contains instructions about the mathematics test. That screen is not part of the scored questions. You can return to the instructions at any time by selecting the <b>Nav</b> button at the top of the screen and then selecting <b>Instr</b>.<br />
            You are permitted to use a calculator on this test, and you may get it out now. Some types of calculators are prohibited. For example, you may not use any version of the TI-89. You are responsible for knowing if your calculator is permitted. Testing staff will check your calculator periodically during the test. If you use a prohibited calculator, you will be dismissed and your tests will not be scored.<br /> You are also responsible for making sure your calculator is working properly. You will not be provided with backup batteries or a replacement calculator.<br />
            Do not share your calculator with another examinee. Do not connect your calculator in any way to the computer.<br />
            If you need to use your backup calculator, raise your hand and testing staff will check it. You may have only one calculator on your workstation or in operation at a time. If you did not bring a backup calculator and yours malfunctions, continue testing.<br />
            If your calculator has games or other functions, you may not use those functions during the test; you may use only the mathematics functions. Keep your calculator flat on your workstation.<br />
            You may use your scratch paper on this test. You will hand that in to the room supervisor at the end of testing.<br />
            Select the <b>Next</b> button to proceed.
            `
        },
        Reading: {
          Title: "Reading Test Directions",
          Direction: `You may now resume testing. Remember, if you are wearing a watch with an alarm or have any other alarm device, it must remain turned off. If you have a cell phone or other electronic device, it must remain powered off and stored out of sight until you leave the test site.<br />
          Clear your workstation of everything except your testing computer, scratch paper, and pencil.<br />
          You will have 35 minutes to complete this section.<br />
          SECTION DIRECTIONS: The first screen in this section contains instructions about the reading test. That screen is not part of the scored questions. You can return to the instructions at any time by selecting the <b>Nav</b> button at the top of the screen and then selecting <b>Instr</b>.<br />
          You may use your scratch paper on this test. You will hand that in to the room supervisor at the end of testing.<br />
          Select the <b>Next</b> button to proceed.
          `
        },
        Science: {
          Title: "Science Test Directions",
          Direction: `You will have 35 minutes to complete this section.<br />
          SECTION DIRECTIONS: The first screen in this section contains instructions about the science test. That screen is not part of the scored questions. You can return to the instructions at any time by selecting the <b>Nav</b> button at the top of the screen and then selecting <b>Instr</b>.<br />
          You may use your scratch paper on this test. You will hand that in to the room supervisor at the end of testing.<br />
          Select the <b>Next</b> button to proceed.`
        }
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.direction {
  line-height: 1.8rem;
  font-size: 0.9rem;
  font-weight: normal;
}
</style>
<style scoped src="@/views/act/assets/css/act.css"></style>

<style scoped src="@/views/act/assets/css/simplebar.css"></style>
